<template>
  <div>
    <a-card :bordered="true" class="header-solid h-full" v-if="!loading" :loading="loading" >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">
              <!--              <a-tooltip placement="top">-->
              <!--                <template slot="title">-->
              <!--                  <span>Refresh Comments</span>-->
              <!--                </template>-->
              <!--                <a-button type="link" @click="resetTable" class="icon-btn" style="color: black">-->
              <!--                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"-->
              <!--                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"-->
              <!--                       class="feather feather-refresh-cw">-->
              <!--                    <polyline points="23 4 23 10 17 10"></polyline>-->
              <!--                    <polyline points="1 20 1 14 7 14"></polyline>-->
              <!--                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>-->
              <!--                  </svg>-->
              <!--                </a-button>-->
              <!--              </a-tooltip>-->
              Comments
            </h5>
          </a-col>
        </a-row>
      </template>
      <a-row type="flex">
        <a-col :span="24">
          <a-comment v-for="(comment,index) in listData" v-if="listData.length" :key="index" :pagination="pagination">
    <span slot="actions" key="comment-nested-reply-to">
      <span key="comment-basic-like">
        <a-tooltip title="Like">
          <a-icon type="heart" style="color:red;font-size: 14px" theme="filled" />

        <span style="padding-left: 8px;cursor: auto;font-size: 14px">
          {{comment.likesCount}}
        </span>
           </a-tooltip>
      </span>
    <span style="margin-left: 10px" key="comment-basic-dislike">
        <a-tooltip title="Replies">
                    <a-icon type="message" style="font-size: 14px" theme="filled" />
        <span style="padding-left: 8px;cursor: auto;font-size: 14px">
          {{ comment.childComments.length }}
        </span>
                  </a-tooltip>

      </span>
          </span>

            <a slot="author" style="font-size: 14px;font-weight: 600">{{ comment.user.name }}</a>
            <a-tooltip slot="datetime" >
              <span>{{ moment(comment.createdAt).fromNow() }}</span>
            </a-tooltip>
            <a-avatar v-if="comment.user.imageUrl" slot="avatar" :src="comment.user.imageUrl" />
            <a-avatar v-else slot="avatar" style="background-color: #1890ff">{{ comment.user.name|getNameForAvatar}}</a-avatar>

            <p slot="content">
              {{
                comment.description
              }}
            </p>
            <a-comment v-for="(child, ind) in comment.childComments" :key="ind+1000" v-if="comment.childComments" >
    <span slot="actions" key="comment-nested-reply-to">
      <span key="comment-basic-like">
        <a-tooltip title="Like">
          <a-icon type="heart" style="color:red;font-size: 14px" theme="filled" />

        <span style="padding-left: 8px;cursor: auto;font-size: 14px">
          {{child.likesCount}}
        </span>
           </a-tooltip>
      </span>
<!--    <span style="margin-left: 10px" key="comment-basic-dislike">-->
<!--        <a-tooltip title="Replies">-->
<!--                    <a-icon type="message" style="font-size: 14px" theme="filled" />-->
<!--        <span style="padding-left: 8px;cursor: auto;font-size: 14px">-->
<!--          12-->
<!--        </span>-->
<!--                  </a-tooltip>-->

<!--      </span>-->
          </span>

              <a slot="author" style="font-size: 14px;font-weight: 600">{{ child.user.name }}</a>
              <a-tooltip slot="datetime">
                <span>{{ moment(child.createdAt).fromNow() }}</span>
              </a-tooltip>
              <a-avatar v-if="child.user.imageUrl" slot="avatar" :src="child.user.imageUrl" />
              <a-avatar v-else slot="avatar" style="background-color: #1890ff">{{ child.user.name|getNameForAvatar}}</a-avatar>

              <p slot="content">
                {{
                  child.description
                }}
              </p>

          </a-comment>
          </a-comment>
          <a-comment v-if="!listData.length">

          <h5  class="font-semibold m-0 text-center text-muted">
            No Comments Found..
          </h5>
          </a-comment>

        </a-col>
      </a-row>
    </a-card>
    <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
      <a-row :gutter="[24, 24]">
        <a-col :span="24" >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">
                  Comments
                </h5>
              </a-col>
            </a-row>
          </template>
          <a-skeleton avatar  active />
          <a-skeleton avatar  active />
          <a-skeleton avatar  active />
          <a-skeleton avatar  active />
          <a-skeleton avatar  active />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      moment,
      loading:false,
      listData: [],
      authorsHeaderBtns: 'all',
      pagination: {
        onChange: page => {
          this.$router.push({name:'Edit Video | Comments',query:{page:page}})
          this.handleTableChange(page)
        },
        current:this.$route.query.page?this.$route.query.page:1,
        pageSize: 10,
      },
      queryData: params => {
        params.type='video'
        return this.$store.dispatch("FETCH_POST_COMMENTS", {params:{params: params},post_id: this.$route.params.id});
      }
    }},
  computed: {
    selectedItem(){
      return this.$store.getters.selectedPost
    }
  },
  methods:{
    handleTableChange(page) {
      const pager = {...this.pagination};
      pager.current = page;
      this.pagination = pager;
      // this.filteredInfo = filters;
      // this.sortedInfo = sorter;
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        // sortField: sorter.field,
        // sortOrder: sorter.order,
        // ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      // params.search=this.search
      this.queryData({
        results: this.pagination.pageSize ? this.pagination.pageSize : 10,
        page: this.pagination.current ? this.pagination.current : 1,
        ...params,
      }).then(({data}) => {
        const pagination = {...this.pagination};
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        this.loading = false;
        this.listData = data.results;
        this.pagination = pagination;
      })
          .finally(()=>{
            this.loadingSearch=false
          });
    },
    postInfo(){
      this.loading=true
      this.$store.dispatch("FETCH_POST",{id:this.$route.params.id})
          .then((res)=>{
          })
          .finally(()=>{
            this.loading=false
          })
    },
  },
  mounted(){
    this.postInfo()
    this.fetch()
  },
}
</script>
